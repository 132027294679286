import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#6DC8BF',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#fafafa'
    }
  },
});
