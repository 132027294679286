import useSWR from 'swr';
import { GET_PROFILE_URL } from '../utils/constants';
import userFetcher from './userFetcher';
import { useProfile } from '../Context/profile';

export default function useUser() {
  const { setProfiles } = useProfile();
  const { data, error } = useSWR(GET_PROFILE_URL, () =>
    userFetcher(setProfiles)
  );

  const loading = !data && !error;
  const loggedOut = !!error || !data;

  return {
    loading,
    loggedOut,
    profiles: data,
    error,
  };
}
