import * as jose from 'jose';
import Cookies from 'js-cookie';
import axiosInstance from '../utils/axiosInstance';
import { GET_PROFILE_URL } from '../utils/constants';
import { IProfiles } from '../typing/profile';

interface FetcherError extends Error {
  status?: number;
}

export default async function userFetcher(
  setProfiles: (profiles: IProfiles[]) => void
) {
  const access_token = Cookies.get('shop_access_token');
  let decoded_access_token = null;

  if (access_token) {
    const unsecuredJwt = jose.decodeJwt(access_token);
    decoded_access_token = unsecuredJwt;
  }

  const currentTime = Date.now() / 1000;
  const isExpired = decoded_access_token?.exp
    ? decoded_access_token.exp < currentTime
    : true;

  if (!access_token || !decoded_access_token || isExpired) {
    const error: FetcherError = new Error('Not authorized!');
    error.status = 403;
    throw error;
  }

  const profiles: IProfiles[] = await axiosInstance
    .get(GET_PROFILE_URL)
    .then((res) => res?.data?.result);
  if (profiles && !!profiles.length) {
    setProfiles(profiles);
  }
  return profiles;
}
