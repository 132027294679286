import {
  createContext,
  Dispatch,
  ReactChild,
  ReactChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import axiosInstance from '../utils/axiosInstance';
import { GET_PROFILE_URL, VERIFY_PROFILE_URL } from '../utils/constants';
import { useNotification } from './notification';
import { useNavigate } from 'react-router-dom';
import { useCookie } from '../Hook/useCookie';
import {
  IProfile,
  IProfileContext,
  IProfileInfo,
  IProfiles,
} from '../typing/profile';

const initialProfile: IProfile = {
  exist: false,
  mrn: '',
  name: '',
  facilityInfo: {
    id: '',
    name: '',
  },
  phoneNo: '',
};

const ProfileContext = createContext<IProfileContext>({
  profile: initialProfile,
  verifyProfile: (profile: IProfileInfo, action: () => void) =>
    new Promise<void>(() => {}),
  registerProfile: () => new Promise<void>(() => {}),
  registerConfirmationDialogStatus: false,
  closeRegisterConfirmationDialog: () => {},
  profiles: [],
  setProfiles: () => {},
  activeProfile: null,
  removeConfirmationDialogStatus: false,
  openRemoveConfirmationDialog: () => {},
  closeRemoveConfirmationDialog: () => {},
  removeProfile: () => new Promise<void>(() => {}),
  setActiveProfile: (profile: IProfiles | null) => {},
  registerIncorrectDialogStatus: false,
  closeRegisterIncorrectDialog: () => {},
});

export const useProfile = () => useContext(ProfileContext);

interface IProfileProviderProps {
  children: ReactChildren | ReactChild;
}

export default function ProfileProvider({ children }: IProfileProviderProps) {
  const [profiles, setProfiles] = useState<IProfiles[]>([]);
  const [profile, setProfile] = useState<IProfile>(initialProfile);
  const [formValues, setFormValues] = useState<IProfileInfo>({
    mrn: '',
    facilityId: '',
    phoneNo: '',
  });
  const [resetForm, setResetForm] = useState<Dispatch<SetStateAction<void>>>(
    () => () => {}
  );
  const [
    registerConfirmationDialogStatus,
    setRegisterConfirmationDialogStatus,
  ] = useState(false);
  const [registerIncorrectDialogStatus, setRegisterIncorrectDialogStatus] =
    useState(false);

  const [removeConfirmationDialogStatus, setRemoveConfirmationDialogStatus] =
    useState(false);
  const [activeProfile, setActiveProfile] = useCookie('activeProfile', null);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (profiles.length === 0 || activeProfile) return;
    setActiveProfile(profiles[0]);
  });

  const closeRegisterConfirmationDialog = () => {
    setRegisterConfirmationDialogStatus(false);
  };

  const closeRegisterIncorrectDialog = () => {
    setRegisterIncorrectDialogStatus(false);
  };

  const openRemoveConfirmationDialog = () => {
    setRemoveConfirmationDialogStatus(true);
  };

  const closeRemoveConfirmationDialog = () => {
    setRemoveConfirmationDialogStatus(false);
  };

  const verifyProfile = async (
    requestedProfile: IProfileInfo,
    action: () => void
  ): Promise<void> => {
    let invalidProfile = false;
    let incorrectProfile = false;
    let resultProfile = await axiosInstance
      .get(VERIFY_PROFILE_URL, {
        params: {
          ...requestedProfile,
          mrn: requestedProfile.mrn.toLocaleLowerCase().trim(),
          phoneNo: `60${Number(requestedProfile.phoneNo)}`,
        },
      })
      .then((res) => res.data?.data)
      .catch((err) => {
        if (err.response.status === 422) {
          showNotification({
            autoHideDuration: 6000,
            severity: 'error',
            message: 'Sorry, your profile information is invalid',
          });
          invalidProfile = true;
          return null;
        }
        if (err.response.status === 403) {
          incorrectProfile = true;
          return null;
        }
        return null;
      });

    if (invalidProfile) return;

    if (incorrectProfile) {
      resultProfile = {
        exist: false,
        mrn: requestedProfile.mrn,
        phoneNo: `60${Number(requestedProfile.phoneNo)}`,
      };
      setProfile(resultProfile);
      setRegisterIncorrectDialogStatus(true);
      return;
    } else {
      if (resultProfile) {
        resultProfile.exist = true;
      } else {
        resultProfile = {
          exist: false,
          mrn: requestedProfile.mrn,
          phoneNo: `60${Number(requestedProfile.phoneNo)}`,
        };
      }
    }

    setProfile(resultProfile);
    setFormValues(requestedProfile);
    setResetForm(() => () => action());
    setRegisterConfirmationDialogStatus(true);
  };

  const registerProfile = async () => {
    await axiosInstance.post(GET_PROFILE_URL, {
      ...formValues,
      mrn: formValues.mrn.toLocaleLowerCase().trim(),
      phoneNo: `60${Number(formValues.phoneNo)}`,
    });
    resetForm();
    setRegisterConfirmationDialogStatus(false);

    const profiles: IProfiles[] = await axiosInstance
      .get(GET_PROFILE_URL)
      .then((res) => res?.data?.result);

    if (!!profiles.length) {
      setProfiles(profiles);
      setActiveProfile(profiles.slice(-1)[0]);
    }
    showNotification({
      autoHideDuration: 6000,
      severity: 'success',
      message: 'The new profile is successfully registered.',
    });

    navigate('/');
  };

  const removeProfile = async () => {
    await axiosInstance.delete(GET_PROFILE_URL, {
      data: {
        mrn: activeProfile?.mrn.toLowerCase(),
        facilityId:
          activeProfile?.facilityId || activeProfile?.facilityInfo?.id,
        phoneNo: activeProfile?.phoneNo,
      },
    });

    setRemoveConfirmationDialogStatus(false);

    const profiles: IProfiles[] = await axiosInstance
      .get(GET_PROFILE_URL)
      .then((res) => res?.data?.result);

    if (profiles) {
      setProfiles(profiles);
    }

    if (profiles.length) {
      setActiveProfile(profiles[0]);
    } else {
      setActiveProfile(null);
    }

    showNotification({
      autoHideDuration: 6000,
      severity: 'success',
      message: 'The profile is successfully removed.',
    });
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        verifyProfile,
        registerProfile,
        registerConfirmationDialogStatus,
        closeRegisterConfirmationDialog,
        profiles,
        setProfiles,
        activeProfile,
        removeConfirmationDialogStatus,
        openRemoveConfirmationDialog,
        closeRemoveConfirmationDialog,
        removeProfile,
        setActiveProfile,
        registerIncorrectDialogStatus,
        closeRegisterIncorrectDialog,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
