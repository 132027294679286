import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const SplashScreen = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box width={400}>
        <img
          src="https://cdn.esyms.com/emailImg/loading-screen-logo-mini.png"
          alt="Esyms Logo"
          width="100%"
        />
        <LinearProgress />
      </Box>
    </div>
  );
};

export default SplashScreen;
