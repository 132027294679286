import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { IProfiles } from '../typing/profile';
import { NODE_ENV } from '../utils/constants';

const cookieOption =
  NODE_ENV === 'localhost' ? { domain: 'localhost' } : { domain: '.esyms.com' };

function getStorageValue(
  key: string,
  defaultValue: IProfiles | null
): IProfiles | null {
  const saved = Cookies.get(key);
  if (!saved) return defaultValue;
  const initial = JSON.parse(saved) as unknown as IProfiles;
  return initial;
}

export const useCookie = (
  key: string,
  defaultValue: IProfiles | null
): [IProfiles | null, Dispatch<SetStateAction<IProfiles | null>>] => {
  const [value, setValue] = useState<IProfiles | null>(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    if (!value) {
      Cookies.remove(key, cookieOption);
    } else {
      Cookies.set(key, JSON.stringify(value), cookieOption);
    }
  }, [key, value]);

  return [value, setValue];
};
