import { IProfiles } from '../typing/profile';

const waNumber = '601116902865';

export const getWhatsappLink = (activeProfile: IProfiles | null) => {
  let waMessage;
  if (activeProfile) {
    waMessage = `Hi, I would like to enquire about my Allmeds order. MRN: ${activeProfile.mrn}`;
  } else {
    waMessage = 'Hi, I would like to enquire about my Allmeds order.';
  }

  return `https://wa.me/${waNumber}?text=${waMessage}`;
};
