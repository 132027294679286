import Cookies from 'js-cookie';
import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${Cookies.get('shop_access_token')}`,
    'content-type': 'application/json',
  },
});

export default axiosInstance;
