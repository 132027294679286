import { lazy, LazyExoticComponent } from 'react';

export interface IRoutes {
  id: string;
  path: string;
  name: string;
  component: LazyExoticComponent<() => JSX.Element>;
}

const routes: IRoutes[] = [
  {
    id: 'home',
    path: '/',
    name: 'Home',
    component: lazy(() => import('../pages/Home')),
  },
  {
    id: 'profile_registration',
    path: '/register',
    name: 'Profile Registration',
    component: lazy(() => import('../pages/Register')),
  },
];

export default routes;
