import { Routes } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import renderRoutes from './Route';
import packageInfo from '../package.json';
import LoadingScreen from './Route/LoadingScreen';
import { Fab } from '@mui/material';
import { getWhatsappLink } from './utils/whatsapp';
import { useProfile } from './Context/profile';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { NODE_ENV } from './utils/constants';

function App() {
  const isProduction = NODE_ENV === 'production' || NODE_ENV === 'staging';
  const { activeProfile } = useProfile();

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingScreen />} //If not pass, nothing appears at the time of new version check.
    >
      <>
        <Routes>{renderRoutes()}</Routes>
        <Fab
          sx={{ position: 'fixed', bottom: '20px', right: '20px' }}
          aria-label="add"
          style={{ backgroundColor: 'rgba(79, 206, 93)', color: 'white' }}
          href={getWhatsappLink(activeProfile)}
        >
          <WhatsAppIcon fontSize="large" />
        </Fab>
      </>
    </CacheBuster>
  );
}

export default App;
