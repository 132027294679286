// Current Domain URL
export const MY_URL = process.env.REACT_APP_MY_URL;

// Shop Backend URI Components
const SHOP_BACKEND_HOST = process.env.REACT_APP_SHOP_BACKEND_HOST;

// Shop Backend URLs
export const GET_PROFILE_URL = `${SHOP_BACKEND_HOST}/esyms/customer/allmeds`;
export const GET_ORDERS_URL = `${SHOP_BACKEND_HOST}/esyms/customer/allmeds/orders`;
export const VERIFY_PROFILE_URL = `${SHOP_BACKEND_HOST}/esyms/customer/allmeds/confirmation`;

// GM Backend URI Components
export const GM_BACKEND_HOST = process.env.REACT_APP_GM_BACKEND_HOST;

export const SHOP_URL = process.env.REACT_APP_SHOP_URL;
export const LOGIN_URL = `${SHOP_URL}/login?redirect=${MY_URL}`;

// Tracking Page
export const ALLMEDS_TRACKING_URL = process.env.REACT_APP_ALLMEDS_TRACKING_URL;

// Config
export const ALLMEDS_FACILITIES = process.env.REACT_APP_FACILITIES;

// NODE ENV
export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
