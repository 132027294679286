import * as React from 'react';
import {
  createContext,
  ReactChild,
  ReactChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

interface ISnackOption {
  autoHideDuration: number;
  severity: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

const NotificationContext = createContext({
  notificationStatus: false,
  showNotification: (options: ISnackOption) => {},
  closeNotification: () => {},
});

export const useNotification = () => useContext(NotificationContext);

interface NotificationProviderProps {
  children: ReactChildren | ReactChild;
}

export default function NotificationProvider({
  children,
}: NotificationProviderProps) {
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(6000);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');
  const [networkStatus, setNetworkStatus] = useState(null);

  const showNotification = (options: ISnackOption) => {
    setNotificationStatus(true);
    setAutoHideDuration(options.autoHideDuration);
    setSeverity(options.severity);
    setMessage(options.message);
  };

  const closeNotification = () => {
    setNotificationStatus(false);
  };

  useEffect(() => {
    const updateNetworkStatus = ({ type }: any) => {
      setNetworkStatus(type);
    };

    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    };
  });

  useEffect(() => {
    if (!networkStatus) return;
    if (networkStatus === 'online') {
      showNotification({
        message: 'You are back to online',
        severity: 'success',
        autoHideDuration: 6000,
      });
    }
    if (networkStatus === 'offline') {
      showNotification({
        message: 'You are currently offline',
        severity: 'error',
        autoHideDuration: 6000,
      });
    }
  }, [networkStatus]);

  return (
    <NotificationContext.Provider
      value={{ notificationStatus, showNotification, closeNotification }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={notificationStatus}
        autoHideDuration={autoHideDuration}
        onClose={closeNotification}
      >
        <Alert
          onClose={closeNotification}
          severity={severity}
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
}
