import useUser from './useUser';
import { ReactChild, ReactChildren, useEffect } from 'react';
import LoadingScreen from '../Route/LoadingScreen';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_URL } from '../utils/constants';
import { useProfile } from '../Context/profile';

export interface AuthorizationProps {
  children: ReactChildren | ReactChild;
}

export default function Authorization({
  children,
}: AuthorizationProps): JSX.Element {
  const { loading, error } = useUser();
  const { profiles } = useProfile();
  const navigate = useNavigate();
  let location = useLocation();

  const redirectToShop = () => {
    window.location.href = String(LOGIN_URL);
  };

  useEffect(() => {
    if (location.pathname === '/register' || loading) return;
    if (profiles?.length === 0) {
      navigate('/register');
    }
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    if (error?.status === 403) {
      redirectToShop();
      return <LoadingScreen />;
    }
  }

  return <>{children}</>;
}
